import {
  initialState,
  Actions,
  State,
  REACTIVATE_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION_SUCCESS,
  REACTIVATE_SUBSCRIPTION_ERROR,
  RESET_REACTIVATE_SUBSCRIPTION,
} from "../../types/subscription-admin/reactivate-subscription";

const reducers = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case REACTIVATE_SUBSCRIPTION:
      return {
        ...state,
        data: null,
        states: {
          loading: true,
          error: false,
          success: false,
        },
      };
    case REACTIVATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        states: {
          loading: false,
          error: false,
          success: true,
        },
      };
    case REACTIVATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        data: null,
        states: {
          loading: false,
          error: action.payload,
          success: false,
        },
      };
    case RESET_REACTIVATE_SUBSCRIPTION:
      return initialState;
    default:
      return state;
  }
};

export default reducers;
