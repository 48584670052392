import action from "./../helpers/rest-client";
import { EditSubscriptionOutDto, UserSubscription } from "../types/subcription-group";

export class ErrorSubscription extends Error { }

class Subscription {
  public async saveUserSubscription(data: UserSubscription) {
    return action
      .Post({
        url: "/api/subscription/save",
        body: data,
      })
      .then((response) => {
        if (response?.output?.statusCode >= 400) {
          return { error: response?.output?.payload?.message };
        }

        return { response };
      })
      .catch((error) => {
        return { error }
      });
  }

  public async getSubscription(id) {
    return action
      .Get({
        url: `/api/subscription/id/${id}`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorSubscription(error.response.statusText);
      });
  }

  public async cancelSubscription(data) {
    const { id: idSubscription } = data;

    return action
      .Post({
        url: `/api/subscription/cancel/${idSubscription}`,
        body: data,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw new ErrorSubscription(error.response.statusText)
      });
  }

  public async reactivateSubscription({ id }) {
    return action
      .Post({
        url: `/api/subscription/reactivate/${id}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw new ErrorSubscription(error.response.statusText)
      });
  }

  public async editSubscription(data: EditSubscriptionOutDto) {
    const { id: idSubscription } = data;

    return action
      .Put({
        url: `/api/subscription/edit/${idSubscription}`,
        body: data
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw new ErrorSubscription(error.response.statusText)
      });
  }


  public async getPaymentUrl(data: {
    platform: string;
    plan: { id: number };
    discounts: { id: number }[];
  }) {
    const result = await action
      .Post({
        url: `/api/subscription/payment-url`,
        body: data,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorSubscription("Error al procesar la subscripción");
      });

    return result;
  }

  public async getSubscriptionCheckoutUrl(data: {
    platform: string;
    plan: { id: number };
    discounts: { id: number }[];
  }) {
    const result = await action
      .Post({
        url: `/api/subscription/preapproval-url`,
        body: data,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorSubscription("Error al procesar la subscripción");
      });

    return result;
  }


}

const subscription = new Subscription();
export default subscription;
