import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../../../../components/Modal";
import Spinner from "../../../../../../components/Loader";
import Button from "../../../../../../components/Button";
import { Center } from "../../../../../../components/StyledComponents";
import {
    Title,
    Text,
    CheckWordMatchInput,
    SubscriptionInfoItem,
} from "./styles";
import {
    cancelSubscription,
    getSubscription,
    resetCancelSubscription,
} from "../../../../../../store/actions/subscription-admin";
import { Subscription } from "../../../../../../types";
import { hideModal } from "../../../../../../store/actions/modal";
import { getGroupSubscribers } from "../../../../../../store/actions/group";
import { Group } from "../../../../../../types/group";
import Snackbar from "../../../../../../components/Snackbar";
import dateHelpers from "../../../../../../helpers/dates-hanlder";

interface ComponentProps {
    subscriptionId: number | null;
    subscription: Subscription | null;
    cancelSubscriptionStates: {
        loading: boolean;
        success: boolean;
        error: any;
    };
    subscriptionStates: {
        loading: boolean;
        success: boolean;
        error: string | boolean;
    };
    group: Group;
}

const Component = ({
    subscriptionId,
    cancelSubscriptionStates,
    subscriptionStates,
    subscription,
    group,
}: ComponentProps) => {
    const dispatch = useDispatch();
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    const onSubmit = () => {
        if (subscription) {
            dispatch(
                cancelSubscription({
                    ...subscription,
                    status: "expired",
                })
            );
        }
    };

    useEffect(() => {
        if (subscriptionId) {
            dispatch(getSubscription(subscriptionId));
        }
    }, [subscriptionId]);

    useEffect(() => {
        if (cancelSubscriptionStates.success) {
            dispatch(getGroupSubscribers({ page: 1, id: group?.id, filters: [] }));
            dispatch(resetCancelSubscription());
            dispatch(hideModal());
        }
    }, [cancelSubscriptionStates]);

    return (
        <>
            <Modal name="expire-member-modal" width="600" showClose={true}>
                {subscriptionStates.success ? (
                    <>
                        <Title>
                            ⚠️ Estás por{" "}
                            <strong>
                                <u>DAR DE BAJA</u>
                            </strong>{" "}
                            la suscripción
                        </Title>

                        <div style={{ height: "16px" }} />

                        {subscription?.type !== "guest" ? (
                            <Text>
                                Al DAR DE BAJA la suscripción se cancelará el debito automático
                                y no se harán más cobros. Esta acción es irreversible. La
                                persona tendrá acceso a los contenidos y grupos de chat hasta la
                                fecha de vencimiento.
                            </Text>
                        ) : (
                            <Text>
                                Al confirmar se removerá a {subscription?.subscriber?.email} de
                                tu {group?.group_type === "membership" ? "Membresia" : "Taller"}
                                . Esto incluye quitarle el acceso a todos los contenidos y
                                expulsarlo de los grupos de chat.
                            </Text>
                        )}

                        <SubscriptionInfoItem>
                            <strong>Información de la suscripción:</strong>
                        </SubscriptionInfoItem>

                        <SubscriptionInfoItem>
                            Suscriptor: <i>{subscription?.subscriber?.email}</i>
                        </SubscriptionInfoItem>
                        <SubscriptionInfoItem>
                            Plan:{" "}
                            <i>
                                "{subscription?.plan?.external_name}"{" "}
                                {subscription?.type !== "guest" ? "- USD $11 / mensual" : ""}
                            </i>
                        </SubscriptionInfoItem>

                        {subscription?.type === "recurring" ? (
                            <SubscriptionInfoItem>
                                Cancelación:{" "}
                                <i>
                                    Al finalizar la suscripción (el{" "}
                                    {dateHelpers.fromUtcSecondsToDateStr(
                                        subscription?.current_term_end,
                                        "DD/MM/YYYY"
                                    )}
                                </i>
                            </SubscriptionInfoItem>
                        ) : (
                            <SubscriptionInfoItem>
                                Cancelación: <i>Inmediatatamente</i>
                            </SubscriptionInfoItem>
                        )}

                        <div style={{ height: "30px" }} />

                        <span>
                            Si estás seguro, escribe <strong>"BAJA"</strong> y luego toca en{" "}
                            <strong>"CONFIRMAR"</strong>
                        </span>

                        <div style={{ height: "5px" }} />

                        <CheckWordMatchInput
                            type="text"
                            placeholder="Escribe aqui la palabra..."
                            name="wordmatch"
                            style={{ marginBottom: "30px" }}
                            onChange={(e) => {
                                setButtonDisabled(
                                    e.target.value.trim().toUpperCase() !== "BAJA"
                                );
                            }}
                        />

                        <Center horizontal="center" vertical="center" gap={12}>
                            <Button
                                options={{
                                    type: "outline",
                                    skin: "purple",
                                    size: "lg",
                                    block: true,
                                }}
                                onClick={() => {
                                    dispatch(hideModal());
                                }}
                            >
                                Volver
                            </Button>

                            <Button
                                options={{
                                    type: "filled",
                                    skin: "purple",
                                    size: "lg",
                                    block: true,
                                }}
                                disabled={buttonDisabled}
                                onClick={onSubmit}
                            >
                                <Center vertical="center" horizontal="center" gap={5}>
                                    {cancelSubscriptionStates?.loading ? (
                                        <Spinner color="#ffff" width={60} />
                                    ) : (
                                        <>Confirmar</>
                                    )}
                                </Center>
                            </Button>
                        </Center>
                    </>
                ) : (
                    <p>Cargando información del suscriptor...</p>
                )}
            </Modal>

            <Snackbar
                visible={
                    cancelSubscriptionStates.success || !!cancelSubscriptionStates.error
                }
                options={{
                    time: 2000,
                    position: "center",
                    type: cancelSubscriptionStates.success ? "success" : "error",
                }}
                onHide={() => dispatch(resetCancelSubscription())}
            >
                {cancelSubscriptionStates.success
                    ? "Miembro cancelado exitosamente"
                    : "Se ha producido un error."}
            </Snackbar>
        </>
    );
};

const state = ({ groupStore, subscriptionAdminStore }) => {
    const { states: cancelSubscriptionStates } =
        subscriptionAdminStore.cancelSubscription;
    const { data: group } = groupStore.group;
    const { data: subscription, states: subscriptionStates } =
        subscriptionAdminStore.subscriptionAdmin;

    return {
        cancelSubscriptionStates,
        group,
        subscription,
        subscriptionStates,
    };
};

export default connect(state)(Component);
