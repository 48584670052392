import { Subscription } from "../../../types";

export const REACTIVATE_SUBSCRIPTION = "REACTIVATE_SUBSCRIPTION";
export const REACTIVATE_SUBSCRIPTION_SUCCESS = "REACTIVATE_SUBSCRIPTION_SUCCESS";
export const REACTIVATE_SUBSCRIPTION_ERROR = "REACTIVATE_SUBSCRIPTION_ERROR";
export const RESET_REACTIVATE_SUBSCRIPTION = "RESET_REACTIVATE_SUBSCRIPTION";

export interface ReactivateSubscription {
  type: typeof REACTIVATE_SUBSCRIPTION;
  payload: { id: number };
}

export interface ReactivateSubscriptionSuccess {
  type: typeof REACTIVATE_SUBSCRIPTION_SUCCESS;
  payload: Subscription;
}

export interface ReactivateSubscriptionError {
  type: typeof REACTIVATE_SUBSCRIPTION_ERROR;
  payload: string | boolean;
}

export interface ResetReactivateSubscription {
  type: typeof RESET_REACTIVATE_SUBSCRIPTION;
  payload: null;
}

export interface State {
  data: Subscription | null
  states: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

export const initialState: State = {
  data: null,
  states: {
    success: false,
    loading: false,
    error: false,
  },
};

export type Actions =
  | ReactivateSubscription
  | ReactivateSubscriptionSuccess
  | ReactivateSubscriptionError
  | ResetReactivateSubscription;
